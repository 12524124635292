import { RichText } from "prismic-reactjs"
import React from "react"
import Card from "react-bootstrap/Card"
import StyledLinkButton from "../common/StyledLinkButton"
import styled from "styled-components"
import { myContext } from "../../context/LanguageContext"
import StyledCard from "../common/StyledCard"

function NewsTicker({ content }) {
  return (
    <div className="news-ticker">
      <StyledNewsContainer>
        <h2 className="news-ticker">News</h2>
        {content.map((newsItem, i) => {
          if (
            !newsItem.node.body[0].primary.archiving_date &&
            newsItem.node.body[0].primary.show_newsticker === true
          ) {
            return (
              <StyledCard key={i} newsTicker>
                <Card.Img
                  variant="top"
                  src={newsItem.node.body[0].primary.image.url}
                />
                <Card.Body>
                  <Card.Title>
                    <RichText
                      render={newsItem.node.body[0].primary.pageTitle}
                    />
                  </Card.Title>
                  <Card.Text>{newsItem.node.body[0].primary.teaser}</Card.Text>
                  <myContext.Consumer>
                    {context => (
                      <StyledLinkButton
                        to={
                          context.isEn
                            ? `../en/${newsItem.node.body[0].primary.slug}`
                            : `../${newsItem.node.body[0].primary.slug}`
                        }
                        newsTicker
                        small
                      >
                        {context.isEn ? "learn more..." : "mehr..."}
                      </StyledLinkButton>
                    )}
                  </myContext.Consumer>
                </Card.Body>
              </StyledCard>
            )
          }
        })}
      </StyledNewsContainer>
    </div>
  )
}

export default NewsTicker

const StyledNewsContainer = styled.div`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap

  background: var(--secondary-color);
  padding: 1rem;
  margin-top: 4rem !important ;
  margin-bottom: 4rem !important ;

  h2 {
    margin-bottom: 1rem;
    color: var(--page-bg-color) !important;
  }

  margin-right: -15px;
  margin-left: -15px;

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    max-height: 800px;
    overflow-y: scroll;
    border-radius: 25px;
    margin: 0 15px 0 15px !important;

    h2 {
      font-size: 1.4rem;
    }
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`
