import React from 'react'
import TwoColAndOneColSection from './TwoColAndOneColSection'

function TwoTextOneInfo({content}) {
    // pass some values down the query tree
    // 
    for (let index = 0; index < content.allPages.edges.length; index++) {
        const element = content.allPages.edges[index];

        element.node.body[0].primary.pageTitle = element.node.page_title;
        element.node.body[0].primary.slug = element.node._meta.uid;
        element.node.body[0].primary.lang = element.node._meta.lang;
    }
    return (
        <TwoColAndOneColSection text={content.allHomepages.edges[0].node} info={content} />
    )
}

export default TwoTextOneInfo
