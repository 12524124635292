import styled from "styled-components"
import { Link } from "gatsby"

const StyledLinkButton = styled(Link)`
  // Small devices
  color: ${props =>
    props.newsTicker
      ? "var(--page-bg-color)"
      : "var(--page-link-color)"} !important;
  border-radius: 15px;
  letter-spacing: 2px;
  text-decoration: none !important;
  border: 1px solid;
  border-color: ${props =>
    props.newsTicker ? "var(--page-bg-color)" : "var(--page-link-color)"};
  display: inline-block;
  padding: 0.6rem 1rem;
  font-size: 1.4rem;

  // Order matters if after :hover it overwtites it
  &:visited {
    color: ${props =>
      props.newsTicker
        ? "var(--page-bg-color)"
        : "var(--page-link-color)"} !important;
  }

  &:active,
  &:focus {
    color: ${props =>
      props.newsTicker
        ? "var(--page-bg-color)"
        : "var(--page-button-hover-color)"} !important;
    background-color: ${props =>
      props.newsTicker
        ? "var(--secondary-color-dark)"
        : "var(--page-link-hover-color)"} !important;
    border-color: ${props =>
      props.newsTicker
        ? "var(--page-bg-color)"
        : "var(--page-link-color)"} !important;
    transition-duration: 0.4s;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    padding: 0.4rem 1rem;
    font-size: 1.1rem;

    &:hover,
    &:active,
    &:focus {
      color: ${props =>
        props.newsTicker
          ? "var(--page-bg-color)"
          : "var(--page-bg-color)"} !important;
      background-color: ${props =>
        props.newsTicker
          ? "var(--secondary-color-dark)"
          : "var(--page-link-color)"} !important;
      border-color: ${props =>
        props.newsTicker
          ? "var(--page-bg-color)"
          : "var(--page-link-color)"} !important;
    }
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
  }
`

export default StyledLinkButton
