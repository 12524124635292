import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SliceZone from "../components/Homepage/sliceZone"

const IndexPage = (props) => {


// Merge allPages Props to allHomepages props

  let seoTitle = ""

  // Set default values if null
  if (props.data.prismic.allHomepages.edges[0].node.seo_title) {
    seoTitle = props.data.prismic.allHomepages.edges[0].node.seo_title
  } else {
    seoTitle = "Pachamama Festival" + " | " + "Home"
  }

  const seoDescription = props.data.prismic.allHomepages.edges[0].node.seo_description

  return (
      <Layout lang={'default'} seoTitle={seoTitle} seoDescription={seoDescription}> 
        <SliceZone node={props.data.prismic} />
      </Layout>
  )
}

export default IndexPage

export const query = graphql`
{
  prismic {
    allPages(where: {page_type: "News"}, sortBy: meta_firstPublicationDate_ASC, lang: "de-ch") {
        edges {
          node {
            page_title
            _meta {
            firstPublicationDate
            lang
            uid
          }
            body {
              ... on PRISMIC_PageBodyNews {
                primary {
                  archiving_date
                  date_issued
                  image
                  teaser
                  text
                  show_newsticker
                }
                type
              }
            }
          }
        }
      }
    allHomepages(lang: "de-ch") {
      edges {
        node {
          seo_description
          seo_title
          body {
            ... on PRISMIC_HomepageBody2_text_1_info {
              type
              fields {
                info
                text
              }
            }
            ... on PRISMIC_HomepageBodyBilderKarussell {
              type
              fields {
                image
              }
            }
            ... on PRISMIC_HomepageBodyText {
              type
              label
              primary {
                rich_text
                site_title
              }
            }
            ... on PRISMIC_HomepageBodyCall_to_action_grid {
              type
              primary {
                section_title
              }
              fields {
                button_label
                call_to_action_title
                content
                featured_image
                button_destination {
                  ... on PRISMIC_Programmuebersicht {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Portrait {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Page {
                    page_title
                    content
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
            ... on PRISMIC_HomepageBodyAnrisstext {
              type
              fields {
                anchor
                teasertext
                title
                button_label
                link_to_correspondig_text {
                  ... on PRISMIC_Page {
                    page_title
                    content
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
            ... on PRISMIC_HomepageBodyCarousel {
              type
              primary {
                section_title
              }
              fields {
                image
                alt
                caption_title
                caption_text
                link {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
