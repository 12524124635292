import React from 'react'
import { Row, Col } from 'react-bootstrap'
import RichText from "../richText"
import NewsTicker from './NewsTicker';

function TwoColAndOneColSection({text, info}) {
    // 
    return (
        <Row className="mt-4 mt-md-1 py-lg-5 pl-lg-4">
            <Col md={12} lg={8}>
                <Row>
                    <Col className="px-lg-5">
                        <RichText render={text.body[2].fields[0].text} />
                    </Col>
                </Row>
            </Col>
            <Col md={12} lg={4}>
                <Row>
                    <Col className="px-3 px-md-5 px-lg-2">
                        <NewsTicker content={info.allPages.edges} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default TwoColAndOneColSection
