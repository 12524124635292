import React from "react"
import CallToActionGrid from "./callToActionGrid"
import TeaserSection from './TeaserSection'
import CardGallery from './Cardgallery'
import Carousel from './Carousel'
import Text from '../Text'
import TwoTextOneInfo from './TwoTextOneInfo'

const SliceZone = ({ node }) => {
  // 
  return (
    <>
      {node.allHomepages.edges[0].node.body.map((bodyContent, i) => {
        // First Row
        if (bodyContent.type === "bilder-karussell") {
          return (
            <Carousel
              key={i}
              images={bodyContent.fields}
            />
          )
        }
        // Second Row
        if (bodyContent.type === "text") {
          return (
            <Text
              key={i}
              title={bodyContent.primary.site_title}
              text={bodyContent.primary.rich_text}
            />
          )
        }
        else { return null; }
      })}
      
      {/* Third Row / Text and Newsbox*/}
      <TwoTextOneInfo content={node} />

      {node.allHomepages.edges[0].node.body.map((bodyContent, i) => {
        // Forth Row
        if (bodyContent.type === "call_to_action_grid") {
          return (
            <CallToActionGrid
              key={i}
              title={bodyContent.primary.section_title}
              callToActions={bodyContent.fields}
            />
          )
        }
        if (bodyContent.type === "anrisstext") {
          return (
            <TeaserSection
              key={i}
              teasers={bodyContent.fields}
            />
          )
        }
        if (bodyContent.type === "carousel") {
          return (
            <CardGallery
              key={i}
              images={bodyContent}
              title={bodyContent.primary.section_title}
            />
          )
        } else { return null; }

      })}
    </>
  )
};

export default SliceZone;