import React from 'react'
import { Row, Col } from 'react-bootstrap'
import RichText from "./richText"
import styled from 'styled-components'
import Address from './Address'

function OneColSection({ pathname, content, contentType, textAlign }) {

    const addressUrls = ["/impressum", "/en/impressum", "/datenschutzerklaerung", "/en/datenschutzerklaerung", "/anreise", "/en/anreise"]

    if (contentType === "component") {
        return (
            <Row className="mt-4 mt-md-5 mb-4 mb-md-5 component">
                <Col md={1} lg={2}></Col>
                <StyledCol md={10}>{content}</StyledCol>
                <Col md={1} lg={2}></Col>
            </Row>
        )
    }

    if (contentType === "video") {
        return (
            <Row className="mt-4 mt-md-5 mb-4 mb-md-5 pb-5 video" style={{ background: "black" }}>
                <Col>{content}</Col>
            </Row>
        )
    }

    for (let index = 0; index < addressUrls.length; index++) {
        const element = addressUrls[index];
        // 
        if (pathname === element && contentType === "address") {
            return (
                <Row className="mt-4 mt-md-5 mb-4 mb-md-5 address">
                    <Col md={1} lg={2}></Col>
                    <Col md={10}>
                        <Address pathname={pathname} />
                    </Col>
                    <Col md={1} lg={2}></Col>
                </Row>
            )
        }
    }

    // applies to NewsSlice.js
    if (contentType === "image") {
        // 
        return (
            <Row className="mt-4 mt-md-5 mb-4 mb-md-5 image">
                <Col md={1} lg={2}></Col>
                <StyledImgCol>
                    {content.tablet || content.laptop ?
                    <img
                        src={content.url}
                        srcSet={`${content.url} ${content.dimensions.width}w,
                        ${content.tablet.url} ${content.tablet.dimensions.width}w,
                        ${content.laptop.url} ${content.laptop.dimensions.width}w`}
                        alt={content.alt}
                    /> : null }
                </StyledImgCol>
                <Col md={1} lg={2}></Col>
            </Row>
        )
    }
    
    if (contentType === "banner") {
        
        return (
            <Row className="mt-4 mt-md-5 mb-4 mb-md-5 banner">
                <StyledImgCol>
                    {content.picture.tablet || content.picture.laptop ?
                        <img
                            src={content.picture.url}
                            srcSet={`
                                ${content.picture.url} ${content.picture.dimensions.width}w,
                                ${content.picture.tablet.url} ${content.picture.tablet.dimensions.width}w,
                                ${content.picture.laptop.url} ${content.picture.laptop.dimensions.width}w`}
                            alt={content.picture.alt}
                        /> : null}
                </StyledImgCol>
            </Row>
        )
    }
    else {
        if (textAlign) {
            return (
                <Row className="mt-4 mt-md-5 mb-4 mb-md-5 text-align">
                    <Col md={1} lg={2}></Col>
                    <Col className="text-center">
                        <RichText render={content} />
                    </Col>
                    <Col md={1} lg={2}></Col>
                </Row>
            )
        } if (pathname !== "/mithelfen" && pathname !== "/infrastruktur"
            && pathname !== "/richtlinien" && pathname !== "/vision" && pathname !== "/en/vision" && pathname !== "/netzwerk"
            && pathname !== "/galerie") {
            return (
                <Row className="mt-4 mt-md-5 mb-4 mb-md-5 else">
                    <Col md={1} lg={2}></Col>
                    <Col>
                        <RichText render={content} />
                    </Col>
                    <Col md={1} lg={2}></Col>
                </Row>
            )
        } else { return null }
    }
}

export default OneColSection

const StyledImgCol = styled(Col)`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

img {
    width: 100%;
    height: auto;
    border-radius: 20px !important;
}

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {

}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {

}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {

        img {
            border-radius: 40px !important;
        }
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}

`
const StyledCol = styled(Col)`

    color: var(--page-text-color);
    font-size: 1.5rem;
`